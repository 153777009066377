<template>
    <div class="caseForPhone">
        <img src="../assets/images/Case/u1801_div.png" width="100%"/>
        <div class="top-tab-dv ft12">
            <div
                class="top-tab-dv-item"
                :class="{ active: active == '*' }"
                @click="active = '*'"
            >
                {{ $t('lang.AllCases') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="{ active: active == 0 }"
                @click="active = 0"
            >
                {{ $t('lang.PigIndustryCase') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="{ active: active == 1 }"
                @click="active = 1"
            >
                {{ $t('lang.PoultryIndustryCase') }}
            </div>
        </div>
        <div class="device-img-dv">
            <div
                class="device-img-item-dv"
                v-for="(item, index) in getShow"
                :key="index"
            >
                <img :src="item.imgUrl"/>
                <div class="text">{{ item.title }}-{{ item.info }}</div>
            </div>
        </div>
        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import {getWebsiteCase} from "@/api";
import AddressForPhone from "./addressForPhone";

export default {
    name: "caseForPhone",
    components: {
        AddressForPhone,
    },
    data() {
        return {
            active: "*",
            showData: [],
        };
    },
    mounted() {
        getWebsiteCase().then((res) => {
            this.showData = res.data.data.map((val) => {
                val["show"] = false;
                return val;
            });
            console.log(this.showData);
        });
    },
    computed: {
        getShow() {
            if (this.active == "*") {
                return this.showData;
            } else {
                console.log(this.showData.filter((val) => val.type == this.active));
                return this.showData.filter((val) => val.type == this.active);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.top-tab-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: rgba($color: #000000, $alpha: 1);
    box-sizing: border-box;
    border-top: solid 1px #444444;
    font-weight: 700;
    width: 100%;
    margin-top: -5px;
}

.top-tab-dv-item {
    width: 33%;
    border: solid 1px #444444;
}

.active {
    color: #a0f31e;
    border-bottom: solid 2px #a0f31e;
}

.device-img-dv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.device-img-item-dv {
    position: relative;
    width: 45%;
    margin-left: 3.3%;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 10px;
    height: 104px;

    flex-shrink: 0;

    img {
        width: 100%;
    }

    .text {
        font-size: 0.6rem;
        background: rgba($color: #000000, $alpha: 0.4);
        color: #fff;
        position: absolute;
        bottom: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px;
        box-sizing: border-box;
    }
}

.device-img-dv {
    min-height: 300px;
}
</style>
